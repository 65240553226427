import * as React from "react";
import { withLocalization } from "@shared-ui/localization-context";
import { AuthenticationState } from "bernie-context";
import { inject } from "mobx-react";
import { AuthContainerFlexModuleResult, AuthContainerProps } from "components/flexComponents/AuthContainer/typings";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";

enum AuthContainerOptions {
  ANONYMOUS = "ANONYMOUS",
  IDENTIFIED = "IDENTIFIED",
  AUTHENTICATED = "AUTHENTICATED",
  IDENTIFIED_AUTHENTICATED = "IDENTIFIED_AUTHENTICATED",
}

@withLocalization
@inject("flexModuleModelStore", "context")
export class AuthContainer extends React.Component<AuthContainerProps> {
  public isIdentifiedAuthenticated = (currentAuth: string, authLevel: string): boolean =>
    authLevel === AuthContainerOptions.IDENTIFIED_AUTHENTICATED &&
    (currentAuth === AuthenticationState.IDENTIFIED || currentAuth === AuthenticationState.AUTHENTICATED);

  public render() {
    const { templateComponent, flexModuleModelStore, blossomComponent, context } = this.props;

    if (!blossomComponent || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const model = flexModuleModelStore.getModel(templateComponent.metadata.id) as AuthContainerFlexModuleResult;

    /* istanbul ignore next */
    if (!model) {
      return null;
    }

    const { authLevel } = model;
    const currentAuth: string = context?.user.authState;
    const extendedMatch = this.isIdentifiedAuthenticated(currentAuth, authLevel);
    const authMatch: boolean = extendedMatch || currentAuth === authLevel;

    if (!authMatch || !blossomComponent || !blossomComponent.shouldRender) {
      return null;
    }
    return <RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />;
  }
}

export default AuthContainer;
